import React, { useEffect, useState } from 'react';
import { Button, Spin, message, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addMedicationProduct } from '../../../redux/action/patient/shop';
import { toast } from 'react-toastify';
import 'react-phone-input-2/lib/style.css';
import { UploadOutlined } from '@ant-design/icons';
import { baseUrl } from '../../../Constant/url';

const AddProducts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputVal, setInputVal] = useState({
        product_name: '',
        manufacturer: '',
        consume_type: '',
        price: '',
    });
    const [loading, setLoading] = useState(false);
    const facilityData = useSelector((state) => state?.auth?.get_user);
    const [readWritePermit, setReadWritePermit] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [mediaFile, setMediaFile] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (name === 'price') {
            formattedValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except dot
            formattedValue = formattedValue.replace(/(\.\d{2})\d+/, '$1'); // Restrict to two decimal places
        } else if (name === 'manufacturer' || name === 'consume_type' || name === 'product_name') {
            if (value.length > 50) {
                // toast.error("Character limit exceeded. Maximum 50 characters allowed.");
                return;
            }
        }

        setInputVal({ ...inputVal, [name]: formattedValue });
    };

    const handleCreate = () => {
        if (mediaFile.length === 0) {
            toast.error("Please upload an image to continue.");
            return;
        }
        setLoading(true);
        const successRes = (res) => {
            setLoading(false);
            toast.success(res?.message);
            navigate('/facility_shop');
        };
        const failureRes = (res) => {
            setLoading(false);
            toast.error(res?.message);
        };
        let data = {
            medication_link: mediaFile,
            medication_title: inputVal?.product_name,
            manufacturer: inputVal?.manufacturer,
            consume_type: inputVal?.consume_type,
            medication_price: Number(inputVal?.price),
        };
        dispatch(addMedicationProduct(data, successRes, failureRes));
    };

    useEffect(() => {
        if (facilityData?.user_type === 'super') {
            setIsSuperAdmin(true);
        } else {
            facilityData?.access?.forEach((itm) => {
                if (itm?.menu_name === 'Patients') {
                    setReadWritePermit(itm);
                }
            });
            setIsSuperAdmin(false);
        }
    }, [facilityData, readWritePermit?.value?.write]);

    // upload product images
    const props = {
        name: 'facility_media',
        action: `${baseUrl}facilities/upload_media`,
        headers: {
            headers: { 'Content-Type': 'multipart/form-data' },
        },
        beforeUpload(file) {
            const isImage = file.type.startsWith('image/');
            if (!isImage) {
                message.error('You can only upload image files!');
            }
            return isImage;
        },
        onChange(info) {
            let mArray = [];
            if (info.file.status !== 'uploading') {
                let filelist = info?.fileList;
                filelist.forEach((m) => {
                    if (m?.response?.data) {
                        mArray.push(m.response.data[0]);
                    }
                });
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
            setMediaFile(mArray);
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
    };

    return (
        <div className="common_content">
            <div className="container-fluid common_container">
                <div className="row">
                    <div className="">
                        <div className="profile_card col-lg-6 col-xxl-5 mx-auto">
                            <h1 className='large_font pb-3'>Add New Product</h1>
                            <div className="row g-4 d-flex align-items-center">
                                <div className="col-md-12">
                                    <div className="extra_padding" style={{ height: "100%" }}>
                                        <div className="row gy-3">
                                            <Spin spinning={loading}>
                                                <div className="col-lg-12">
                                                    <label className='small_font'>Upload Product image <span className='text-danger'>*</span></label>
                                                    <div className="d-flex align-items-center">
                                                        <Upload {...props} className='pt-3 pb-2 product-ant'>
                                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                        </Upload>
                                                    </div>
                                                </div>
                                            </Spin>
                                            <div className="col-lg-12">
                                                <label className='small_font'>Product Name <span className='text-danger'>*</span></label>
                                                <input
                                                    className='input_fields'
                                                    name="product_name"
                                                    onChange={handleInputChange}
                                                    value={inputVal.product_name}
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <label className='small_font'>Manufacturer/Marketer <span className='text-danger'>*</span></label>
                                                <input
                                                    className='input_fields'
                                                    name="manufacturer"
                                                    onChange={handleInputChange}
                                                    value={inputVal.manufacturer}
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <label className='small_font'>Consume Type <span className='text-danger'>*</span></label>
                                                <input
                                                    className='input_fields'
                                                    name="consume_type"
                                                    onChange={handleInputChange}
                                                    value={inputVal.consume_type}
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <label className='small_font'>Price <span className='text-danger'>*</span></label>
                                                <input
                                                    className='input_fields'
                                                    name="price"
                                                    onChange={handleInputChange}
                                                    type='number'
                                                    value={inputVal.price}
                                                />
                                            </div>
                                            <div className="w-50 mx-auto">
                                                <Button type='ghost' onClick={handleCreate} className="common_btn mt-3">Save</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddProducts;
